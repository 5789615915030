import axios from "axios";

export const state = {
  customFields: [],
};

export const getters = {
  getCustomFields: (state) => state.customFields,
};

export const mutations = {
  setCustomFields(state, newValue) {
    state.customFields = newValue;
  },
};

export const actions = {
   async createCustomFields({ dispatch, commit }, fields) {
     commit("setCustomFields", fields)
   }
};
