import { cacheAction } from "vuex-cache";
import { getSettings, getMaintenanceModeSettings } from "../../helpers/settings/settings.service";
import pluralize from "pluralize";
import { COMMON } from "../../constants/common";
import { createTimesheet } from "@/services/timesheet.service";
import { createSettings, updateSettings, updateMaintenanceModeSettings, syncEvereeCandidates } from "@/services/settings.service";


export const state = {
  settings: undefined,
  loading: false,
  saving: false,
  refetching: false,
  previousPayload: undefined,
  currentTab: "timesheets",
  systemSettingsCurrentTab: "url_branding",
  industries: [],
  positions: [],
  specialties: [],
};

export const getters = {
  getSettings: (state) => state.settings,
  getPrimaryColor: (state) => state.settings?.primary_color,
  getSecondaryColor: (state) => state.settings?.secondary_color,
  getCompanyLogo: (state) => state.settings?.logo,
  getCurrentTab: (state) => state.currentTab,
  getSystemSettingsCurrentTab: (state) => state.systemSettingsCurrentTab,
  getMobileAppSettings: (state) => state?.settings?.mobile_app,
  isSaving: (state) => state.saving,
  isLoading: (state) => state.loading,
  isRefetching: (state) => state.refetching,
  getIndustries: (state) => state.industries,
  getPositions: (state) => state.positions,
  getSpecialties: (state) => state.specialties,
};

export const mutations = {
  SET_SETTINGS: (state, value) => {
    state.settings = value;
  },
  /**
   * @param {UserState} state
   * @param {boolean} value
   */
  SET_LOADING: (state, value) => {
    state.loading = value;
  },
  SET_REFETCHING: (state, value) => {
    state.refetching = value;
  },
  SET_SAVING: (state, value) => {
    state.saving = value;
  },
  SET_PREVIOUS_PAYLOAD: (state, value) => {
    state.previousPayload = value;
  },
  SET_CURRENT_TAB: (state, value) => {
    state.currentTab = value;
  },
  SET_SYSTEM_SETTINGS_CURRENT_TAB: (state, value) => {
    state.systemSettingsCurrentTab = value;
  },
  SET_INDUSTRIES: (state, value) => {
    state.industries = value;
  },
  SET_POSITIONS: (state, value) => {
    state.positions = value;
  },
  SET_SPECIALTIES: (state, value) => {
    state.specialties = value;
  },
};

export const actions = {
  fetchSettings: cacheAction(async ({ cache, commit }, companyId) => {
    commit("SET_PREVIOUS_PAYLOAD", companyId);

    let settings =
      ["undefined", undefined].includes(
        sessionStorage.getItem(COMMON.SETTINGS)
      ) !== true
        ? JSON.parse(sessionStorage.getItem(COMMON.SETTINGS))
        : undefined;

    if (!settings) {
      settings = await cache.dispatch("FETCH_SETTINGS", companyId);
      sessionStorage.setItem(COMMON.SETTINGS, JSON.stringify(settings));
    }

    let dashboardLabels = {
      ondemand: {
        plural: "Shifts",
        singular: "Shift",
      },
      placements: {
        plural: "Placements",
        singular: "Placement",
      },
      orders: {
        plural: "Orders",
        singular: "Order",
      },
    };

    if (settings?.dashboard_labels) {
      for (const [key, value] of Object.entries(settings.dashboard_labels)) {
        dashboardLabels[key] = {
          ...value,
          plural: pluralize.isPlural(value.label)
            ? value.label
            : pluralize.plural(value.label),
          singular: pluralize.isSingular(value.label)
            ? value.label
            : pluralize.singular(value.label),
        };
      }
    }

    const primary_color = "#6691e7";
    const secondary_color = "#e7ebf4";
    
    commit("SET_SETTINGS", {
      primary_color,
      secondary_color,
      ...settings,
      dashboard_labels: dashboardLabels,
    });
  }),

  FETCH_SETTINGS: async ({ commit, rootState }, companyId) => {
    const company_id = companyId ?? rootState.user.user.company_id;
    const settings = await getSettings(company_id);
    return settings;
  },

  invalidateSettings: cacheAction(
    async ({ cache, state, dispatch, commit }) => {
      sessionStorage.removeItem(COMMON.SETTINGS);

      const isCleared = cache.clear("FETCH_SETTINGS");
      commit("SET_REFETCHING", true);
      await dispatch("fetchSettings", state.previousPayload);
      commit("SET_REFETCHING", false);
    }
  ),

  createSettings: async (
    { commit, state, dispatch },
    { data, onSuccess, onError }
  ) => {
    commit("SET_SAVING", true);

    try {
      const response = await createSettings(
        state.previousPayload,
        data.payload
      );
      commit("SET_SAVING", false);
      dispatch("invalidateSettings");
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      commit("SET_SAVING", false);
      if (onError) onError(error);
    }
  },

  updateSettings: async (
    { commit, dispatch, state },
    { data, params, onSuccess, onError }
  ) => {
    commit("SET_SAVING", true);

    try {
      const response = await updateSettings(
        state.previousPayload,
        data,
        params
      );
      commit("SET_SAVING", false);
      dispatch("invalidateSettings");
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      commit("SET_SAVING", false);
      if (onError) onError(error);
    }
  },

  setCurrentTab: ({ commit }, { tab }) => {
    commit("SET_CURRENT_TAB", tab);
  },

  setMaintenanceMode: async ({ commit, rootState }, { data, onSuccess, onError }) => {
    try {
      const response = await updateMaintenanceModeSettings(data);
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      if (onError) onError(error);
    }
  },

  getMaintenanceModeValue: async ({ commit, rootState }, companyId) => {
    const company_id = companyId ?? rootState.user.user.company_id;
    const settings = await getMaintenanceModeSettings(company_id);
    return settings;
  },

  setSystemSettingsCurrentTab: ({ commit }, { tab }) => {
    commit("SET_SYSTEM_SETTINGS_CURRENT_TAB", tab);
  },
  
  setIndustries: ({ commit }, industries) => {
    commit("SET_INDUSTRIES", industries);
  },

  setPositions: ({ commit }, positions) => {
    commit("SET_POSITIONS", positions);
  },

  setSpecialties: ({ commit }, specialties) => {
    commit("SET_SPECIALTIES", specialties);
  },

  syncCandidates: async ({ commit, state, dispatch, rootState }, { data, onSuccess, onError }) => {
    try {
      const company_id = data.company_id ?? rootState.user.user.company_id;
      const response = await syncEvereeCandidates({ company_id, ...data });
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      if (onError) onError(error);
    }
  }
};
