import { cacheAction } from "vuex-cache";
import ApiService from "@/helpers/api/ApiService.js";
import {
  getTimesheet,
  getTimesheets,
  getTimesheetsCount,
  getTimesheetLogs,
  getTimesheetUsers,
  createTimesheet,
  archiveTimesheet, restoreTimesheet,
  invoiceTimesheet,
  lockUnlockTimesheet,
  removeTimecard,
  addTimecard,
  exportTimesheetToPayroll
} from "../../services/timesheet.service";
import { AxiosError } from "axios";
import { toast } from "vue-sonner";
import router from "../../router";
import { ROUTE } from "../../constants/routes";
const currentUser = JSON.parse(localStorage.getItem("client-portal-user"));

/**
 * @typedef TimesheetsState
 * @property {Array<Location[]>} locations
 * @property {boolean} loading
 */

/**  @type {TimesheetsState} */
export const state = {
  dataTableQuery: {
    status: "all", // default=all; all, submitted, approved, denied, new
    location: "all",
    dateRange: "",
    custom_search: "",
    company_id: currentUser && currentUser.company_id,
    from: "",
    to: "",
  },
  companySettings: {
    timesheetUsers: undefined,
    locations: undefined,
  },

  // ============= UPDATED =============== //
  timesheets: [],
  timesheet: undefined,
  timesheetLogs: undefined,
  totalTimesheets: 0,
  submittedCount: null,
  loading: {
    timesheet: false,
    timesheets: false,
    invoiceTimesheet: false,
    lockUnlockTimesheet: false,
    archiveTimesheet: false,
    restoreTimesheet: false,
    exportTimesheetToPayroll: false,
    createTimecard: false,
    logs: false,
    users: false,
  },
  submitting: false,
  activeRequests: 0,
  previousPayload: {
    timesheet: undefined,
    timesheets: undefined,
    logs: undefined,
    users: undefined,
  },
  timesheetUsers: [],
};

export const getters = {
  todos: (state) => state.todos,
};

export const mutations = {
  setDataTableQuery(state, payload) {
    if (payload.dateRange) {
      const dateRange = payload.dateRange.split(" to ");
      if (dateRange.length >= 2) {
        payload.from = dateRange[0];
        payload.to = dateRange[1];
      } else {
        payload.from = "";
        payload.to = "";
      }
    }
    state.dataTableQuery = {
      ...payload,
    };
  },
  setTableStatus(state, payload) {
    state.dataTableQuery.status = payload;
  },
  setCompanySettings(state, payload) {
    state.companySettings = {
      timesheetUsers: payload.users,
      locations: payload.locations,
    };
  },

  // ============= UPDATED =============== //
  SET_TIMESHEETS: (state, value) => {
    state.timesheets = value;
  },
  SET_TOTAL_TIMESHEETS: (state, value) => {
    state.totalTimesheets = value;
  },
  SET_SUBMITTED_COUNT: (state, value) => {
    state.submittedCount = value;
  },
  SET_LOADING: (state, { key, value }) => {
    state.loading[key] = value;
  },
  SET_SUBMITTING: (state, value) => {
    state.submitting = value;
  },
  CLEAR_TIMESHEETS: (state) => {
    state.timesheets = [];
  },
  SET_ACTIVE_REQUEST: (state, count) => {
    state.activeRequests = count;
  },
  SET_PREVIOUS_PAYLOAD: (state, { key, payload }) => {
    state.previousPayload[key] = payload;
  },
  SET_TIMESHEET: (state, value) => {
    state.timesheet = value;
  },
  SET_TIMESHEET_LOGS: (state, value) => {
    state.timesheetLogs = value;
  },
  SET_TIMESHEET_USERS: (state, value) => {
    state.timesheetUsers = value;
  },
};

export const actions = {
  changeTableStatus({ commit }, payload) {
    commit("setTableStatus", payload);
  },

  async fetchCompanySettings({ commit }) {
    const {
      data: { data: payload },
      data: { success },
    } = await ApiService.get(
      `/client-portal/settings/${currentUser.company_id}`
    );
    if (success) {
      commit("setCompanySettings", payload);
    }
  },

  // ============= UPDATED =============== //
  // TIMESHEET
  fetchTimesheet: cacheAction(async ({ commit, cache }, timesheetId) => {
    commit("SET_PREVIOUS_PAYLOAD", { key: "timesheet", payload: timesheetId });

    const response = await cache.dispatch("GET_TIMESHEET", timesheetId);

    commit("SET_TIMESHEET", response?.data || undefined);
  }),

  GET_TIMESHEET: async ({ commit, dispatch }, timesheetId) => {
    commit("SET_LOADING", { key: "timesheet", value: true });

    try {
      const timesheets = await getTimesheet(timesheetId);

      commit("SET_LOADING", { key: "timesheet", value: false });

      return timesheets;
    } catch (error) {
      commit("SET_LOADING", { key: "timesheet", value: false });

      if (error instanceof AxiosError) {
        toast.error(error.response.data.message);
        dispatch("invalidateTimesheets");
        router.push(ROUTE.TIMESHEETS.BASE);
      }

      return undefined;
    }
  },

  // TIMESHEETS
  fetchTimesheets: cacheAction(async ({ commit, cache }, payload) => {
    commit("SET_PREVIOUS_PAYLOAD", { key: "timesheets", payload });

    const response = await cache.dispatch("GET_TIMESHEETS", payload);

    if (state.activeRequests === 0) {
      commit("SET_TIMESHEETS", response?.data || []);
      commit("SET_TOTAL_TIMESHEETS", response?.meta?.total || 0);
    }
  }),

  GET_TIMESHEETS: async ({ commit, state }, payload) => {
    // This will increment active request
    // This is to manage request so loading will not be 'false' if active request is greater than 1
    commit("SET_ACTIVE_REQUEST", state.activeRequests + 1);

    commit("SET_LOADING", { key: "timesheets", value: true });

    const timesheets = await getTimesheets(payload);

    // This will decrement after the request was done
    commit("SET_ACTIVE_REQUEST", state.activeRequests - 1);

    // This will check if activeRequests is equal to 0
    // Then will set loading to false
    if (state.activeRequests === 0) {
      commit("SET_LOADING", { key: "timesheets", value: false });
    }

    return timesheets;
  },

  // TIMESHEET LOGS
  fetchTimesheetLogs: cacheAction(async ({ commit, cache }, timesheetId) => {
    commit("SET_TIMESHEET_LOGS", undefined);
    commit("SET_PREVIOUS_PAYLOAD", { key: "logs", payload: timesheetId });

    const timesheetLogs = await cache.dispatch(
      "GET_TIMESHEET_LOGS",
      timesheetId
    );

    commit("SET_TIMESHEET_LOGS", timesheetLogs);
  }),

  GET_TIMESHEET_LOGS: async ({ commit }, timesheetId) => {
    commit("SET_LOADING", { key: "logs", value: true });

    const timesheetLogs = await getTimesheetLogs(timesheetId);

    commit("SET_LOADING", { key: "logs", value: false });

    return timesheetLogs || undefined;
  },

  // TIMESHEET USERS
  fetchTimesheetUsers: cacheAction(async ({ commit, cache }) => {
    commit("SET_TIMESHEET_USERS", undefined);
    commit("SET_PREVIOUS_PAYLOAD", { key: "users", payload: null });

    const timesheetUsers = await cache.dispatch("GET_TIMESHEET_USERS");

    commit("SET_TIMESHEET_USERS", timesheetUsers);
  }),

  GET_TIMESHEET_USERS: async ({ commit }) => {
    commit("SET_LOADING", { key: "users", value: true });

    const timesheetUsers = await getTimesheetUsers();

    commit("SET_LOADING", { key: "users", value: false });

    return timesheetUsers || [];
  },

  invalidateTimesheetUsers: cacheAction(async ({ cache, state, dispatch }) => {
    const isCleared = cache.clear("GET_TIMESHEET_USERS");

    if (isCleared) {
      dispatch("fetchTimesheetUsers", state.previousPayload.timesheetUsers);
    }
  }),

  invalidateTimesheets: cacheAction(async ({ cache, state, dispatch }) => {
    const isCleared = cache.clear("GET_TIMESHEETS");

    if (isCleared) {
      dispatch("fetchTimesheets", state.previousPayload.timesheets);
    }
  }),

  invalidateTimesheet: cacheAction(async ({ cache, state, dispatch }) => {
    const isCleared = cache.clear("GET_TIMESHEET");

    if (isCleared) {
      dispatch("fetchTimesheet", state.previousPayload.timesheet);
    }
  }),

  fetchSubmittedCount: async ({ commit }, payload) => {
    const count = await getTimesheetsCount("submitted", payload);

    commit("SET_SUBMITTED_COUNT", count ?? 0);
  },

  createTimesheet: async ({ commit }, { data, onSuccess, onError }) => {
    commit("SET_SUBMITTING", true);

    try {
      const timesheet = await createTimesheet(data);
      commit("SET_SUBMITTING", false);
      if (onSuccess) onSuccess(timesheet);
    } catch (error) {
      commit("SET_SUBMITTING", false);
      if (onError) onError(error);
    }
  },

  archiveTimesheet: async ({ commit }, { id, onSuccess, onError }) => {
    commit("SET_LOADING", { key: "archiveTimesheet", value: true });

    try {
      const response = await archiveTimesheet(id);
      commit("SET_LOADING", { key: "archiveTimesheet", value: false });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      commit("SET_LOADING", { key: "archiveTimesheet", value: false });
      if (onError) onError(error);
    }
  },

  archiveTimesheets: async ({ commit }, { ids, onSuccess, onError }) => {
    commit("SET_LOADING", { key: "archiveTimesheet", value: true });

    try {
      const response = await archiveTimesheet(ids);
      commit("SET_LOADING", { key: "archiveTimesheet", value: false });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      commit("SET_LOADING", { key: "archiveTimesheet", value: false });
      if (onError) onError(error);
    }
  },

  lockUnlockTimesheets: async ({ commit }, { ids, type, onSuccess, onError }) => {
    commit("SET_LOADING", { key: "lockUnlockTimesheet", value: true });
    try {
      const response = await lockUnlockTimesheet({ ids, type });
      commit("SET_LOADING", { key: "lockUnlockTimesheet", value: false });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      commit("SET_LOADING", { key: "lockUnlockTimesheet", value: false });
      if (onError) onError(error);
    }
  },

  invoiceTimesheets: async ({ commit }, { ids, onSuccess, onError }) => {
    commit("SET_LOADING", { key: "invoiceTimesheet", value: true });
    try {
      const response = await invoiceTimesheet(ids);
      commit("SET_LOADING", { key: "invoiceTimesheet", value: false });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      commit("SET_LOADING", { key: "invoiceTimesheet", value: false });
      if (onError) onError(error);
    }
  },

  restoreSingleTimesheet: async ({ commit }, { id, onSuccess, onError }) => {
    commit("SET_LOADING", { key: "restoreTimesheet", value: true });

    try {
      const response = await restoreTimesheet(id);
      commit("SET_LOADING", { key: "restoreTimesheet", value: false });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      commit("SET_LOADING", { key: "restoreTimesheet", value: false });
      if (onError) onError(error);
    }
  },

  restoreMultipleTimesheet: async ({ commit }, { ids, onSuccess, onError }) => {
    commit("SET_LOADING", { key: "restoreTimesheet", value: true });

    try {
      const response = await restoreTimesheet(ids);
      commit("SET_LOADING", { key: "restoreTimesheet", value: false });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      commit("SET_LOADING", { key: "restoreTimesheet", value: false });
      if (onError) onError(error);
    }
  },

  createTimecard: async ({ commit }, { data, onSuccess, onError }) => {
    commit("SET_LOADING", { key: "createTimecard", value: true });
    try {
      const response = await addTimecard(data);
      commit("SET_LOADING", { key: "createTimecard", value: false });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      commit("SET_LOADING", { key: "createTimecard", value: false });
      if (onError) onError(error);
    }
  },

  deleteTimecard: async ({ commit }, { id, onSuccess, onError }) => {
    try {
      const response = await removeTimecard(id);
      if (onSuccess) onSuccess(response);
    } catch (error) {
      if (onError) onError(error);
    }
  },

  exportTimesheetToPayroll: async ({ commit }, { payload, onSuccess, onError }) => {
    commit("SET_LOADING", { key: "exportTimesheetToPayroll", value: true });

    try {
      const response = await exportTimesheetToPayroll(payload);
      commit("SET_LOADING", { key: "exportTimesheetToPayroll", value: false });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      commit("SET_LOADING", { key: "exportTimesheetToPayroll", value: false });
      if (onError) onError(error);
    }
  },
};
