export const ROUTE = {
  TIMESHEETS: {
    BASE: "/timesheets",
  },
  WHOSWORKING: {
    BASE: "/whos-working",
  },
  SETTINGS: {
    ADMIN: {
      BASE: "/admin-settings",
      DEFAULT: "/admin-settings/client-portal",
      CLIENT_PORTAL: "/admin-settings/client-portal",
      NOTIFICATIONS: "/admin-settings/notifications",
      SHIFT: "/admin-settings/shift",
      SCHEDULING_TIMESHEETS: "/admin-settings/scheduling-timesheets",
      CANDIDATES: "/admin-settings/candidates",
      USERS: "/admin-settings/users",
      ROLES: "/admin-settings/roles",
      BRANCHES: "/admin-settings/roles",
      MOBILE_APP: {
        BASE: "/admin-settings/mobile-app/",
        NEW_USER_JOURNEY: {
          BASE: "/admin-settings/mobile-app/new-user-journey",
          LIST: "/admin-settings/mobile-app/new-user-journey/list",
          LANDING_PAGE:
            "/admin-settings/mobile-app/new-user-journey/landing-page",
          KEYWORDS: "/admin-settings/mobile-app/new-user-journey/keywords",
          INTERVIEW: "/admin-settings/mobile-app/new-user-journey/interview",
          CTA: "/admin-settings/mobile-app/new-user-journey/custom-cta",
        },
        MAINTENANCE_MODE: {
          BASE: "/admin-settings/mobile-app/maintenance-mode",
        }
      },
      CUSTOMREPORTS: "/custom-reports",
      COMPANIES: "/admin-settings/companies",
      JOBS: "/admin-settings/jobs",
      INTEGRATIONS: "/admin-settings/integrations",
      IMPORT: "/admin-settings/import",
    },
  },
  CANDIDATES: {
    BASE: "/candidates",
    ID: "/candidates/:candidateId",
    UPDATE: "/candidates/update/:candidateId",
    PROFILE: {
      NOTES: "/candidates/:candidateId/notes",
      DOCUMENTS: "/candidates/:candidateId/documents",
      PLACEMENTS: "/candidates/:candidateId/placements",
      RESUME: "/candidates/:candidateId/resume",
      AVAILABILITY: "/candidates/:candidateId/availability",
      PREFERENCES: "/candidates/:candidateId/preferences",
    },
  },
  ONBOARDING: {
    BASE: "/onboarding",
  },
  COMPANIES: {
    BASE: "/companies",
  },
  PLACEMENTS: {
    BASE: "/placements",
  },
  ORDERS: {
    BASE: "/orders",
  },
  MESSAGES: {
    BASE: "/messages",
  },
  PUBLIC: {
    JOBS: "/:company_id/jobs"
  },
  MESSAGING: {
    BASE: "/messaging",
    PUSH_NOTIFICATION: "/push-notification",
  },
};
