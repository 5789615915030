import { axios } from "@/utils/axios";
import { ENDPOINTS } from "../constants/endpoints";
import { isAxiosError } from "axios";
import moment from "moment-timezone";

export const getTimesheets = async (payload) => {
  try {
    const response = await axios.get(ENDPOINTS.TIMESHEETS.BASE, {
      params: {
        ...payload,
      },
    });

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      console.error(error.message);
      return;
    }

    console.error(error);
  }
};

export const getTimesheet = async (timesheetId) => {
  try {
    const response = await axios.get(
      `${ENDPOINTS.TIMESHEETS.BASE}/${timesheetId}`
    );

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      console.error(error.message);
      throw error;
    }

    console.error(error);
    throw error;
  }
};

export const updateTimesheetStatus = (timesheets, status, comment, manager) => {
  return Promise.all([
    ...timesheets.map(
      async (timesheet) =>
        await axios.put("/v2/app/timerecord/timesheet", {
          timesheet_id: timesheet._id,
          user_id: timesheet.user_id,
          location_id: timesheet.location_id,
          status,
          comment,
          manager,
        })
    ),
  ]);
};

export const getTimesheetsCount = async (status, payload) => {
  const response = await axios.get(`${ENDPOINTS.TIMESHEETS.COUNT}/${status}`, {
    params: {
      ...payload,
    },
  });

  return response.data?.data?.count;
};

export const getTimesheetLogs = async (timesheetId) => {
  const response = await axios.get(
    `${ENDPOINTS.TIMESHEETS.LOGS}/${timesheetId}`
  );

  return response.data?.data;
};

export const updateTimesheet = async (timesheetId, payload) => {
  const response = await axios.put(
    `${ENDPOINTS.TIMESHEETS.BASE}/${timesheetId}`,
    {
      ...payload,
    }
  );

  return response.data;
};

/**
 * @typedef ExportTimesheetFields
 * @property {string} label Column Label
 * @property {string} key Timesheet Field Name
 */

/**
 * @typedef ExportTimesheetsPayload
 * @property {string} companyId
 * @property {("csv" | "pdf")} type Export File Type
 * @property {string[]} timesheetIds
 * @property {ExportTimesheetFields[]} fields
 * @property {boolean|undefined} autoDownload
 * @property {object} filters
 * @property {string} filename
 */

/**
 *
 * @param {ExportTimesheetsPayload} payload
 * @returns
 */
export const exportTimesheets = async ({
  companyId,
  type,
  fields,
  timesheetIds,
  filename,
  filters,
  autoDownload,
}) => {
  const responseType = type === "csv" ? "blob" : "arraybuffer";

  const response = await axios.post(
    ENDPOINTS.TIMESHEETS.EXPORT[type.toUpperCase()],
    { timesheet_ids: timesheetIds, fields },
    { responseType, params: { ...filters } }
  );

  const datetime = moment(new Date()).format("YYYYmmdd-HHMMss");
  const defaultFilename = `${companyId}-timesheets-${datetime}.${
    type === "csv" || type === "paychex-csv" ? "csv" : "pdf"
  }`;

  if (autoDownload) {
    const csvData = response.data;

    const blob = new Blob([csvData], {
      type: type === "csv" ? "text/csv" : "application/pdf",
    });
    const csvUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = filename ?? defaultFilename;

    link.click();

    URL.revokeObjectURL(csvUrl);

    return;
  }

  return response;
};

export const exportCustomTimesheets = async ({
  name,
  type,
  companyId,
  timesheetIds,
  filename,
}) => {
  const responseType = type === "csv" ? "blob" : "arraybuffer";
  const response = await axios.post(
    ENDPOINTS.TIMESHEETS.EXPORT.CUSTOM,
    { timesheet_ids: timesheetIds, custom_report: name },
    { responseType }
  );

  const datetime = moment(new Date()).format("YYYYmmdd-HHMMss");
  const defaultFilename = `${name || companyId}-timesheets-${datetime}.${
    type === "csv" ? "csv" : "pdf"
  }`;

  const csvData = response.data;

  const blob = new Blob([csvData], {
    type: type === "csv" ? "text/csv" : "application/pdf",
  });
  const csvUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = csvUrl;
  link.download = filename ?? defaultFilename;

  link.click();

  URL.revokeObjectURL(csvUrl);

  return;
};

export const getTimesheetUsers = async () => {
  const response = await axios.get(ENDPOINTS.TIMESHEETS.USERS);

  return response?.data?.data;
};

export const createTimesheet = async (payload) => {
  const response = await axios.post(ENDPOINTS.TIMESHEETS.CREATE, payload);

  return response?.data?.data;
};

/**
 *
 * @param {(string | string[])} payload
 * @returns
 */
export const archiveTimesheet = async (payload) => {
  if (typeof payload === "string") {
    const response = await axios.delete(
      `${ENDPOINTS.TIMESHEETS.BASE}/${payload}`
    );

    return response.data;
  }

  const response = await axios.delete(ENDPOINTS.TIMESHEETS.BASE, {
    data: {
      timesheet_ids: payload,
    },
  });

  return response.data;
};

export const invoiceTimesheet = async (payload) => {
  console.log({ payload });
  if (typeof payload === "string") {
    const response = await axios.put(
      `${ENDPOINTS.TIMESHEETS.INVOICE}/${payload}`
    );

    return response.data;
  }

  const response = await axios.put(ENDPOINTS.TIMESHEETS.INVOICE, {
    timesheet_ids: payload,
  });

  return response.data;
};

export const lockUnlockTimesheet = async (payload) => {
  let timesheets =
    typeof payload.ids === "string" ? [payload.ids] : payload.ids;

  const response = await axios.put(ENDPOINTS.TIMESHEETS.INVOICE + "/toggle", {
    type: payload.type,
    timesheet_ids: timesheets,
  });

  return response.data;
};

export const restoreTimesheet = async (payload) => {
  if (typeof payload === "string") {
    const response = await axios.put(
      `${ENDPOINTS.TIMESHEETS.RESTORE}/${payload}`
    );

    return response.data;
  }

  const response = await axios.put(ENDPOINTS.TIMESHEETS.RESTORE, {
    timesheet_ids: payload,
  });

  return response.data;
};

export const addTimecard = async (payload) => {
  const response = await axios.post(ENDPOINTS.TIMECARDS.CREATE, payload);
  return response.data;
};

export const removeTimecard = async (payload) => {
  const response = await axios.delete(`${ENDPOINTS.TIMECARDS.DELETE}/${payload}`);

  return response.data;
};

/**
 * Submit Multiple Timesheets
 * @param {string[]} timesheetIds
 * @returns {Promise<any>}
 */
export const submitTimesheets = async (timesheetIds) => {
  const response = await axios.post(ENDPOINTS.TIMESHEETS.SUBMIT, {
    timesheet_ids: timesheetIds,
  });

  return response.data;
};

export const exportTimesheetToPayroll = async (payload) => {
  if (typeof payload.ids === "string") {
    const response = await axios.post(
      `${ENDPOINTS.TIMESHEETS.PAYROLL}/${payload.ids}`
    );

    return response.data;
  }

  const response = await axios.post(ENDPOINTS.TIMESHEETS.PAYROLL, {
    timesheet_ids: payload.ids,
    type: payload.type,
  });

  return response.data;
};